import moment from 'moment';

import {
  simpleDate,
  formatMroType,
  convertFuel,
  convertMileage,
  formatVehicleFuelType,
  formatMroTypeCertID,
  convertCentsToDollars,
  formatRucGroupName,
  roundNumber,
} from 'utilities/format';

import {
  getAllSystemStatements,
  getAllPaymentCards,
  getParticipants,
} from './data';

const dueDateOfMonth = localStorage.getItem('ruc:configuration:BILLING_BALANCE_DUE_DATE_OF_MONTH') || '15';
const pilotStartDate = localStorage.getItem('ruc:configuration:PILOT_START_DATE') || '2024-08-01T07:00:00.000Z';

const getDueDateForStatement = (statementMonth, statementCreatedAt) => {
  const defaultDueDate = moment(statementMonth).add(1, 'months').set('date', dueDateOfMonth).toISOString();

  if (defaultDueDate <= statementCreatedAt) {
    return moment(statementCreatedAt).add(1, 'months').set('date', dueDateOfMonth).toISOString();
  } else {
    return defaultDueDate;
  }
};

// setup dates
const month = [
  moment(pilotStartDate),
  moment(pilotStartDate).add(1, 'Month'),
  moment(pilotStartDate).add(2, 'Month'),
  moment(pilotStartDate).add(3, 'Month'),
  moment(pilotStartDate).add(4, 'Month'),
  moment(pilotStartDate).add(5, 'Month'),
];


function mapFields(participant, vehicle, participantStatements, hasCard) {
  const id = vehicle.id;

  const data = Object.assign({
    'RecordID': id,
    'AccountID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'Payment Method Stored': hasCard ? 'Yes' : 'No',
    'RUC Group': formatRucGroupName(participant.pilotProgram?.shortName),
    'VIP': participant?.flags?.isVIP ? 'True' : 'False',
    'VIN': vehicle.vin,
    'Vehicle Registration State': vehicle.registrationState,
    'Vehicle Fuel Type': formatVehicleFuelType(vehicle.type),
    'Vehicle Fuel Economy': vehicle.epaVehicleCombinedMpg,
    'MRO ID': vehicle.mro?.deviceSerialNumber || '',
    'MRO Type': formatMroType(vehicle.mroType),
    'MRO Cert ID': formatMroTypeCertID(participant.mroDevicePreference),
  }, monthlyValues(participantStatements, vehicle));

  return data;
}

function monthlyValues(statements, vehicle) {
  const output = {};
  const odo = vehicle.reports == undefined ? [] : vehicle.reports.sort((a, b) => {
    return moment(a.tsReportDate).isBefore(b.tsReportDate) ? -1 : 1;
  });

  for (let i = 0; i < 6; i++) {
    const statementMonth = month[i].format('YYYY-MM');
    const statement = statements.find(({ month }) => month === statementMonth);
    if (statement) {
      const tripSummary = statement.tripsSummary &&
        statement.tripsSummary.filter(({ vehicleId }) => vehicleId === vehicle.id) || [];
      const adjSummary = statement.adjustmentsSummary &&
        statement.adjustmentsSummary.filter(({ vehicleId }) => vehicleId === vehicle.id) || {};
      const vehicleSummary = statement.vehiclesSummary &&
        statement.vehiclesSummary.find(({ vehicleId }) => vehicleId === vehicle.id) || {};

      const odoReadingsBetweenStatementPeriod = [];

      odo.forEach((item, index) => {
        const { tsReportDate } = item;

        if (statement.periodFrom <= tsReportDate && tsReportDate <= statement.periodTo) {
          // inherit the last record from previous month
          if (odoReadingsBetweenStatementPeriod.length === 0 && odo[index - 1]) {
            odoReadingsBetweenStatementPeriod.push(odo[index - 1]);
          }
          odoReadingsBetweenStatementPeriod.push(item);
        }
      });

      const odoStart = odoReadingsBetweenStatementPeriod[0] || {};
      const odoEnd = odoReadingsBetweenStatementPeriod[odoReadingsBetweenStatementPeriod.length - 1] || {};

      let daysToPay = '';
      if (statement.paidAt) {
        daysToPay = moment(statement.paidAt).diff(moment(statement.createdAt), 'days');
      }

      // Trip Summary
      // Statement already convert the mileage and fuel
      let totalMileage = 0;
      let chargeableMileage = 0;
      let nonChargeableMileage = 0;
      let nonChargeableMileageCA = 0;
      tripSummary.forEach(({ stateCode, privateMileage, publicMileage }) => {
        totalMileage += (publicMileage + privateMileage);

        // check to see if CA miles.  If outside of califnoria, then all miles are non-chargeable
        if (stateCode === 'CA') {
          chargeableMileage += publicMileage;
          nonChargeableMileage += privateMileage;
          nonChargeableMileageCA += privateMileage;
        } else {
          nonChargeableMileage += (publicMileage + privateMileage);
        }
      });

      // Adjustment Summary
      // Statement already convert the mileage and fuel
      let totalAdjMileage = 0;
      let totalAdjMileageFee = 0;
      let totalFuelUsage = 0;
      let totalFuelTaxCredit = 0;
      adjSummary.forEach(({ adjMileage, adjMileageFee, adjFuel, adjFuelFee }) => {
        totalAdjMileage += (adjMileage || 0);
        totalAdjMileageFee += (adjMileageFee || 0);

        // Statement does not convert the adjFuel from liter to gallon
        const convertedAdjFuel = adjFuel ? convertFuel(adjFuel, 'gal') : 0;
        totalFuelUsage += (convertedAdjFuel);
        totalFuelTaxCredit += (adjFuelFee || 0);
      });

      const dueDate = getDueDateForStatement(statement.month, statement.createdAt);
      const isPaidByDueDate = statement.paidAt <= dueDate ? 'Yes' : 'No';

      // handle nan for vehicleSummary values.  If it is NaN, then set it to 0
      const fuelTaxCredit = (vehicle.type === 'electric') ? 0 :
        (vehicleSummary.fuelFee) ? vehicleSummary.fuelFee : 0;
      const rifFee = (vehicle.type !== 'electric') ? 0 :
        (vehicleSummary.fuelFee) ? vehicleSummary.fuelFee : 0;

      output[`M${i + 1}_Stmt Issue Date`] = simpleDate(statement.createdAt);
      output[`M${i + 1}_MRO Start Report Date`] = simpleDate(odoStart.tsReportDate);
      output[`M${i + 1}_ODO Start Value`] = convertMileage(odoStart.odometer, 'mi');
      output[`M${i + 1}_MRO End Report Date`] = simpleDate(odoEnd.tsReportDate);
      output[`M${i + 1}_ODO End Value`] = convertMileage(odoEnd.odometer, 'mi');
      output[`M${i + 1}_Total Mileage`] = roundNumber(totalMileage);
      output[`M${i + 1}_Total Chargeable Mileage`] = roundNumber(chargeableMileage);
      output[`M${i + 1}_Total Non-Chargeable Mileage`] = roundNumber(nonChargeableMileage);
      output[`M${i + 1}_Total Non-Chargeable Mileage_CA`] = roundNumber(nonChargeableMileageCA);
      output[`M${i + 1}_Total RUC`] = convertCentsToDollars(vehicleSummary.mileageFee);
      output[`M${i + 1}_Total Fuel Usage`] = roundNumber(vehicleSummary.fuel);
      output[`M${i + 1}_Total Fuel Tax Credit`] = convertCentsToDollars(Math.abs(fuelTaxCredit));
      output[`M${i + 1}_Total RIF Credit`] = convertCentsToDollars(Math.abs(rifFee));
      output[`M${i + 1}_Net RUC Revenue`] = convertCentsToDollars(vehicleSummary.taxDifference);
      output[`M${i + 1}_Adjustment_Total Mileage`] = roundNumber(totalAdjMileage);
      output[`M${i + 1}_Adjustment_Total RUC`] = convertCentsToDollars(totalAdjMileageFee);
      output[`M${i + 1}_Adjustment_Total Fuel Usage`] = roundNumber(totalFuelUsage);
      output[`M${i + 1}_Adjustment_Total Fuel Tax Credit`] = convertCentsToDollars(totalFuelTaxCredit);
      output[`M${i + 1}_Adjustment_Total Net RUC Revenue`] = convertCentsToDollars(totalAdjMileageFee - totalFuelTaxCredit);
      output[`M${i + 1}_Statement Amount Payment Date`] = simpleDate(statement.paidAt);
      output[`M${i + 1}_Statement Amount Paid`] = convertCentsToDollars(statement.paidAmount);
      output[`M${i + 1}_Statement No. of Days to Pay`] = daysToPay;
      output[`M${i + 1}_Statement Amount Due Paid by Due Date`] = isPaidByDueDate;
    } else {
      output[`M${i + 1}_Stmt Issue Date`] = '';
      output[`M${i + 1}_Stmt Due Date`] = '';
      output[`M${i + 1}_MRO Start Report Date`] = '';
      output[`M${i + 1}_ODO Start Value`] = '';
      output[`M${i + 1}_MRO End Report Date`] = '';
      output[`M${i + 1}_ODO End Value`] = '';
      output[`M${i + 1}_Total Mileage`] = '';
      output[`M${i + 1}_Total Chargeable Mileage`] = '';
      output[`M${i + 1}_Total Non-Chargeable Mileage`] = '';
      output[`M${i + 1}_Total Non-Chargeable Mileage_CA`] = '';
      output[`M${i + 1}_Total RUC`] = '';
      output[`M${i + 1}_Total Fuel Usage`] = '';
      output[`M${i + 1}_Total Fuel Tax Credit`] = '';
      output[`M${i + 1}_Total RIF Credit`] = '';
      output[`M${i + 1}_Net RUC Revenue`] = '';
      output[`M${i + 1}_Adjustment_Total Mileage`] = '';
      output[`M${i + 1}_Adjustment_Total RUC`] = '';
      output[`M${i + 1}_Adjustment_Total Fuel Usage`] = '';
      output[`M${i + 1}_Adjustment_Total Fuel Tax Credit`] = '';
      output[`M${i + 1}_Adjustment_Total Net RUC Revenue`] = '';
      output[`M${i + 1}_Statement Amount Payment Date`] = '';
      output[`M${i + 1}_Statement Amount Paid`] = '';
      output[`M${i + 1}_Statement No. of Days to Pay`] = '';
      output[`M${i + 1}_Statement Amount Due Paid by Due Date`] = '';
    }
  }

  return output;
}

export default {
  name: 'Billing Summary (Monthly Totals)',
  useDateRange: false,
  async process() {
    const [
      participants,
      statements,
      cards,
    ] = await Promise.all([
      getParticipants(),
      getAllSystemStatements(),
      getAllPaymentCards(),
    ]);

    // create lookups for statements and cards
    const statementLookUp = {};
    const cardLookUp = {};
    statements.forEach((statement) => {
      const statements = statementLookUp[statement.username] || [];
      statements.push(statement);
      statementLookUp[statement.username] = statements;
    });
    cards.forEach((card) => {
      // only show the stored card not one time payment
      if (card.showAsStored) {
        cardLookUp[card.username] = card;
      }
    });

    try {
      const data = participants.map((participant) => {
        const hasCard = Object.hasOwn(cardLookUp, participant.username);
        const participantStatements = statementLookUp[participant.username] || [];

        return participant.vehicles.items.map((vehicle) => {
          return mapFields(participant, vehicle, participantStatements, hasCard);
        });
      });

      return data.flat().filter((x) => x);
    } catch (e) {
      console.log(e);
      return [];
    }
  },
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import LinkButton from 'components/Table/LinkButton';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { listPilotPrograms } from 'graphql/queries';
import { updateParticipant, createParticipantConnection } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { formatAddress } from 'utilities/format';

import {
  participantStatus,
  participantContactType,
  mroDevicePreferences,
} from 'utilities/constants';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import Participant from 'pages/Admin/Participant';
import CloseAccountDialog from 'pages/Admin/components/CloseAccountDialog';
import { sortBy } from 'utilities/sorting';
import AccountStatusSelector from './AccountStatusSelector';

const LIST_PARTICIPANTS_CACHE_KEY = 'ParticipantsTable-ListParticiapnts';
const useStyles = makeStyles((theme) => ({
  spinner: {
    marginTop: theme.spacing(20),
  },
}));

const flagKeys = [
  'hasIntegrityViolation',
  'isBillingOverdue',
  'isBillingDefault',
  'isInactive',
  'isVinMismatch',
  'isLegislator',
  'isVIP',
  'isGovernmentEmployee',
  'isCaliforniaElected',
  'agreeGlobalParticipantAgreement',
  'agreeGlobalPrivacyPolicy',
];

const cache = {};

const getAllParticipants = async (statuses = []) => {
  let records = [];
  await Promise.all(statuses.map(async (status) => {
    const data = cache[status] ? cache[status] : await asyncListAll( /* GraphQL */ `
      query GetParticipantsByStatus(
        $status: Status
        $sortDirection: ModelSortDirection
        $filter: ModelParticipantFilterInput
        $limit: Int
        $nextToken: String
      ) {
        getParticipantsByStatus(
          status: $status
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            username
            accountNo
            closedDate
            closedReason
            email
            firstName
            middleName
            lastName
            phoneNumber
            preferredContactType
            status
            statusMessage
            mroDevicePreference
            mroManualCapture
            roadChargeCreditCents
            sumFuelTaxCreditCents
            sumMileageUserFeeCents
            participantPilotProgramId
            pilotProgram {
              id
              name
            }
            flags {
              hasIntegrityViolation
              isBillingOverdue
              isBillingDefault
              isInactive
              isVinMismatch
              isLegislator
              isVIP
              isGovernmentEmployee
              isCaliforniaElected
              agreeGlobalParticipantAgreement
              agreeGlobalPrivacyPolicy
            }
            governmentAffiliation
            address {
              address1
              address2
              city
              state
              postalCode
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`, { status });
    records = [...records, ...data];

    cache[status] = data;
  }));

  return records;
};

function ParticipantsTable({ data: inData, title = 'Participants', description = '', viewer = 'admin' }) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [pilotPrograms, setPilotPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // close account
  const [closeAccountDialog, setCloseAccountDialog] = useState(false);
  const [accountToClose, setAccountToClose] = useState(null);

  const columns = [
    {
      name: 'status',
      label: 'Status',
      edit: {
        type: 'select',
        menu: participantStatus.map((item) => {
          return { label: item.toUpperCase(), value: item };
        }),
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return value ? value.toUpperCase() : '';
        },
        customFilterListOptions: {
          render: (x) => `Status:${x}`,
        },
      },
    },
    {
      name: 'statusMessage',
      label: 'Status Message',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'flags.hasIntegrityViolation',
      label: 'Integrity Violation',
      type: 'checkbox',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'flags.isVinMismatch',
      label: 'VIN Mismatch',
      type: 'checkbox',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'flags.isBillingOverdue',
      label: 'Overdue transactions 5 days',
      type: 'checkbox',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'flags.isBillingDefault',
      label: 'Overdue transactions 10 days',
      type: 'checkbox',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'closedDate',
      label: 'Closed Date',
      type: 'date',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'closedReason',
      label: 'Closed Reason',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'participantPilotProgramId',
      label: 'Program',
      edit: {
        type: 'select',
        menu: pilotPrograms.map(({ id, shortName }) => {
          return { label: shortName, value: id };
        }),
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          const { shortName } = pilotPrograms.find(({ id }) => id === value) || {};
          return shortName;
        },
        customFilterListOptions: {
          render: (x) => `Program:${x}`,
        },
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      edit: {
        type: 'text',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'middleName',
      label: 'Middle Name',
      edit: {
        type: 'text',
      },
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      edit: {
        type: 'text',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: 'Username',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'accountNo',
      label: 'Account Number',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      edit: {
        type: 'text',
      },
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      edit: {
        type: 'text',
      },
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'preferredContactType',
      label: 'Contact Type',
      edit: {
        type: 'select',
        menu: participantContactType,
      },
      options: {
        display: false,
        filter: true,
        sort: true,
        customFilterListOptions: {
          render: (x) => `Contact Type:${x}`,
        },
        filterOptions: {
          names: ['email', 'phone'],
        },
      },
    },
    {
      name: 'mroDevicePreference',
      label: 'Reporting Method',
      edit: {
        type: 'select',
        menu: mroDevicePreferences,
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(item) {
          const preference = mroDevicePreferences.find(({ value }) => value === item) || {};
          const { label = 'N/A' } = preference;
          return label;
        },
        customFilterListOptions: {
          render: (x) => `Reporting Method:${x}`,
        },
      },
    },
    {
      name: 'mroManualCapture',
      label: 'Manual',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
        customFilterListOptions: {
          render: (x) => `Manual Capture:${x ? 'Yes' : 'No'}`,
        },
        filterOptions: {
          renderValue: (x) => x ? 'Yes' : 'No',
        },
      },
    },
    {
      name: 'flags.isVIP',
      label: 'VIP',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'flags.isGovernmentEmployee',
      label: 'Government Employee',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'flags.isCaliforniaElected',
      label: 'California Elected Official',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender: formatAddress,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(username) {
          return (
            <LinkButton
              path={`/participant/${username}`}
              label="View participant details"
            />
          );
        },
      },
    },
  ];

  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData, rowMeta) {
      const { username } = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns}>
          <Participant username={username} hideInfo={true} isNested={true} />
        </NestedTableContainer>
      );
    },
  };

  const onUpate = async (item, dataIndex) => {
    global.logger.debug('onUpdate', item, dataIndex);
    global.logger.debug('Participant data:', data, data[dataIndex]);

    const input = {
      username: item.username,
      updatedBy: localStorage.getItem('ruc:username'),
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        if (name.indexOf('.') !== -1) {
          const columnParts = name.split('.');
          if (typeof input[columnParts[0]] === 'undefined') {
            input[columnParts[0]] = {};
          }
          input[columnParts[0]][columnParts[1]] = item[name];
        } else {
          input[name] = item[name];
        }
      }
    });

    if (item.status === 'closed') {
      setCloseAccountDialog(true);
      setAccountToClose({
        dataIndex,
        account: input,
      });
      return;
    }

    // participant updates
    const promises = [asyncRetryMutation(
      updateParticipant, { input }, { clearCacheKeys: [LIST_PARTICIPANTS_CACHE_KEY] },
    )];

    if (
      data[dataIndex].mroDevicePreference !== 'telematics' &&
      item.mroDevicePreference === 'telematics'
    ) {
      promises.push(asyncRetryMutation(createParticipantConnection, {
        input: {
          resourceProvider: 'smartcar',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          username: item.username,
          forceRestoreConnection: true,
        },
      }));
    }

    await Promise.all(promises);

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };

  const handleOnClose = (response) => {
    if (response) {
      const { dataIndex, account } = response;
      Object.assign(data[dataIndex], account);
      setData([...data]);
    }
    setCloseAccountDialog(false);
    setAccountToClose(null);
  };

  const formatParticipantRecords = (records = []) => {
    return records
      .sort(sortBy('firstName'))
      .sort(sortBy('lastName'))
      .sort(sortBy('status'))
      .map((participant) => {
        participant.flags = participant.flags || {};

        flagKeys.forEach((key) => {
          participant.flags[key] = participant.flags[key] || false;
        });

        return participant;
      });
  };

  const handleSetPilotPrograms = async () => {
    if (pilotPrograms.length > 0) return;

    const pilotProgramRecords = await asyncListAll(listPilotPrograms);
    setPilotPrograms(pilotProgramRecords.sort(sortBy('name')).map((item) => {
      item.displayName = `${item.name} (${item.shortName})`;
      return item;
    }));
  };

  const onSelectAccountStatus = async (checkedStatus) => {
    try {
      setIsLoading(true);

      const [
        participantRecords,
      ] = await Promise.all([
        getAllParticipants(checkedStatus),
        handleSetPilotPrograms(),
      ]);

      setData(formatParticipantRecords(participantRecords));
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (inData) {
        await handleSetPilotPrograms();

        setData(formatParticipantRecords(inData));
      }
    })();
  }, [inData]);

  return (
    <div data-test-id="participants-table">
      {!inData && <AccountStatusSelector onUpdate={(checkedStatus) => onSelectAccountStatus(checkedStatus)} />}
      {isLoading ?
        <Grid container className={classes.spinner} justify="center" alignItems="center">
          <CircularProgress color="inherit" />
        </Grid> :
        <Table
          title={title}
          description={description}
          data={data}
          columns={columns}
          options={options}
          onUpdateItem={onUpate}
        />}
      <CloseAccountDialog
        isOpen={closeAccountDialog}
        accountToClose={accountToClose}
        onClose={handleOnClose}
      />
    </div>
  );
}

ParticipantsTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
  viewer: PropTypes.string,
};

export default ParticipantsTable;
